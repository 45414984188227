import { animate } from "motion"
import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

window.animate = animate

export default class extends Controller {
  static targets = ["body", "modal"]

  static values = {
    url: String
  }

  connect() {
    document.addEventListener('turbo:submit-end', this.handleSubmit)
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.handleSubmit)
  }

  close(event) {
    event.preventDefault()
    this.animateOut()
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      if (e?.detail?.fetchResponse?.response?.redirected) {
        Turbo.visit(e.detail.fetchResponse.response.url)
      }
      this.close()
    } else {
      // We got a validation error from the backend
    }
  }

  open(event) {
    event.preventDefault()

    this.setInitialState()

    const frame = this.modalTarget.querySelector("turbo-frame")
    if (frame.src == event.params.url) {
      // Frame has already been loaded, just show it
      this.animateIn()
    } else {
      // Frame is NOT loaded yet, wait for it to render
      frame.addEventListener("turbo:frame-render", (event) => {
        this.animateIn()
      })

      frame.src = event.params.url
    }
  }

  animateIn() {
    this.modalTarget.classList.remove("hidden")

    animate(this.modalTarget, { opacity: 1 })
    animate(
      this.bodyTarget,
      { "top": "0%" }
    ).finished.then(() => {
      this.focusOnFirstTextField()
    })
  }

  animateOut() {
    animate(
      this.modalTarget,
      { opacity: 0 }
    ).finished.then(() => {
      this.modalTarget.classList.add("hidden")
    })
    animate(this.bodyTarget, {"top": "100%"})
  }

  focusOnFirstTextField() {
    this.element.querySelector("input[type=text]")?.focus()
  }

  setInitialState() {
    this.bodyTarget.style.setProperty("top", "100%")
    this.modalTarget.style.setProperty("opacity", "0")
  }
}
